import './payment-breakdown.scss';

import Conditional from "@/components/conditional";
import LoadableComponent from "@/components/loadable-component";
import { InvoiceContext } from "@/contexts/invoice-context";
import Invoice from "@/models/invoice";
import PaymentEvent from "@/models/payment-event";
import invoicePdfApi from "@/services/apis/invoice/invoice-pdf-api";
import paymentApi from "@/services/apis/payment/payment-api";
import { formatAsCurrency } from "@/services/formatters";
import { logException } from '@/services/logging';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Card, CardBody, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PaymentTable from "./payment-table";
import api from '@/services/api';
import Payment from '@/models/payment';

interface Props {
    screen: 'invoice' | 'payment' | 'receipt';
}

function PaymentBreakdown(props: Props) {
    const [invoice, setInvoice] = useState<Invoice>();
    const navigate = useNavigate();

    const invoiceContext = useContext(InvoiceContext);

    useEffect(() => {
        setInvoice(invoiceContext.invoice);
    }, [invoiceContext.invoice]);

    const viewInvoice = () => {
        invoicePdfApi.getInvoicePdf('invoice', invoiceContext.invoice.PdfEncryptionValue, invoiceContext.invoice.PdfEncryptionSalt, openPdfInNewTab);
    }

    const openPdfInNewTab = (pdf: string) => {
        const pdfWindow = window.open("") as any;
        pdfWindow.document.write(
            `<embed src='data:application/pdf;base64,${pdf}' type="application/pdf" width="100%" height="100%"></embed>`
        );
    }

    const hasRemainingBalance = () => {
        return (invoice?.RemainingBalance || 0) > 0;
    }

    useEffect(() => {
        if (props.screen === 'payment')
            window.onmessage = onPayFieldMessage;
    }, [props.screen]);

    const onPayFieldMessage = (event: MessageEvent<PaymentEvent>) => {
        if (event.data.Event === 'lock-payment')
            paymentApi.lockPayment(getPaymentLock(event.data.PaymentInfo, true), onPaymentLock(event.source), api.knownErrorFallback);
        else if (event.data.Event === 'unlock-payment')
            paymentApi.lockPayment(getPaymentLock(event.data.PaymentInfo, false), () => { }, api.knownErrorFallback);
        else if (event.data.Event === 'payment-success')
            paymentApi.submitPayment(event.data.PaymentInfo, onPaymentSubmit(event.data.PaymentInfo.PaymentId), api.knownErrorFallback);
        else if (event.data.Event === 'payrix-error') {
            onPayrixError(event.data.ErrorMessage);
        }
        else if (event.data.Event === 'cancel-payment')
            navigate('/invoice');
    }

    const getPaymentLock = (payment: Payment, shouldLock: boolean)=>{
        return { 
            PaymentId: payment.PaymentId,
            ConvenienceFee: payment.ConvenienceFee, 
            IsLocked: shouldLock 
        };
    }

    const onPaymentLock = (eventSource: MessageEventSource | null) => () => {
        eventSource?.postMessage({ event: "submit" });
    }

    const onPaymentSubmit = (paymentId: string) => () => {
        invoiceContext.reloadInvoice();
        navigate(`/receipt/${paymentId}`);
    }

    const onPayrixError = (errorMessage?: string) => {
        logException(new Error(errorMessage));
        toast.error(errorMessage ?? "An error occurred while submitting your payment. Please try again later.");
    }

    return (
        <Card className='f-card f-payment-breakdown'>
            <CardBody>
                <LoadableComponent loading={!invoice}>
                    <Row className="f-header-link">
                        <h3><b>{`Invoice #${invoice?.InvoiceNumber}`}</b></h3>
                        <a href='#' onClick={viewInvoice}>View Invoice <FontAwesomeIcon icon="external-link"></FontAwesomeIcon></a>
                    </Row>
                    <Row>
                        Status: <span className={`f-status f-status-${invoice?.PaidStatus.toLowerCase()}`}>{`${invoice?.PaidStatus.toUpperCase()}`}</span>
                    </Row>
                    <Row>
                        {`Date: ${invoice?.Date}`}
                    </Row>
                    <Row>
                        {`Due On: ${invoice?.DueDate}`}
                    </Row>
                    <Row>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Summary of Charges</th>
                                    <th className="f-right-col">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Invoice Total</td>
                                    <td className="f-right-col">{formatAsCurrency(invoice?.Amount ?? null)}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><b>TOTAL CHARGES</b></td>
                                    <td className="f-right-col">{formatAsCurrency(invoice?.Amount ?? null)}</td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Row>
                    <PaymentTable Payments={invoice?.Payments || []} Title="Payments" />
                    <PaymentTable Payments={invoice?.RefundedPayments || []} Title="Cancelled/Failed Payments" />
                    <Row className='f-amount-due'>
                        <span className="f-total-label">Total Amount Due</span>
                        <span className="f-total-label">{formatAsCurrency(hasRemainingBalance() ? (invoice?.RemainingBalance || 0) : 0) + " USD"}</span>
                    </Row>
                    <hr />
                    <Row className="f-button-row">
                        <Conditional condition={!!invoice?.ACHConvenienceFeeSetting.FeeAmount || !!invoice?.CreditCardConvenienceFeeSetting.FeeAmount}>
                            <span className='f-con-fee-note'>Note: a non-refundable convenience fee will be added based on the selected payment method if this invoice is paid online.  The amount will be shown prior to authorization of payment.</span>
                        </Conditional>
                    </Row>
                </LoadableComponent>
            </CardBody>
        </Card>
    )
}

export default PaymentBreakdown;