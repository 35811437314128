import './view-invoice.scss';

import Conditional from "@/components/conditional";
import HelpSection from "@/components/help-section";
import ProjectTitleBar from "@/components/layout/project-title-bar";
import LoadableComponent from "@/components/loadable-component";
import PdfViewer from "@/components/pdf-viewer";
import ScrollablePage from "@/components/scrollable-page";
import { InvoiceContext } from "@/contexts/invoice-context";
import Invoice from "@/models/invoice";
import invoicePdfApi from "@/services/apis/invoice/invoice-pdf-api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, CardBody, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import PaymentBreakdown from "./payment-breakdown";

function ViewInvoice() {
    const [invoice, setInvoice] = useState<Invoice>();
    const [invoicePdf, setInvoicePdf] = useState<string>();
    const [documentType, setDocumentType] = useState<'invoice' | 'hourly' | 'attachment'>('invoice');

    const invoiceContext = useContext(InvoiceContext);

    useEffect(() => {
        setInvoice(invoiceContext.invoice);
    }, [invoiceContext.invoice]);

    useEffect(() => {
        setInvoicePdf(undefined);
        if (!!invoice && !!invoice.CanBeViewed)
            invoicePdfApi.getInvoicePdf(documentType, invoice.PdfEncryptionValue, invoice.PdfEncryptionSalt, setInvoicePdf);
    }, [documentType, invoice]);

    const getInvoiceLink = (linkDocumentType: 'invoice' | 'hourly' | 'attachment', label: string) => {
        if (linkDocumentType == documentType)
            return <span>{label}</span>;
        return <a onClick={() => setDocumentType(linkDocumentType)}>{label}</a>;
    }

    const hasRemainingBalance = () => {
        return (invoice?.RemainingBalance || 0) > 0;
    }

    return (
        <ScrollablePage className='f-view-invoice' title='Invoice Payment'>
            <Conditional condition={!!invoice && !!invoice.CanBeViewed}>
                <ProjectTitleBar />
                <Row className="f-body-row">
                    <Col xs="12" lg="8">
                        <Card className='f-card f-invoice-card'>
                            <CardBody>
                                <Row className='f-card-header-row'>
                                    <Col xs={12} lg={6}>
                                        <h3>Invoice Payment</h3>
                                        <span>View your invoice below and select a payment option.</span>
                                        <Row className="f-doc-links d-none d-lg-block">
                                            {getInvoiceLink('invoice', 'Invoice')}
                                            <Conditional condition={!!invoice?.HasHourlyDetail}>
                                                | {getInvoiceLink('hourly', 'Hourly Detail')}
                                            </Conditional>
                                            <Conditional condition={!!invoice?.HasExpenseAttachments}>
                                                | {getInvoiceLink('attachment', 'Expense Attachments')}
                                            </Conditional>
                                        </Row>
                                    </Col>
                                    <Col className="f-amount-col" xs={12} lg={6}>
                                        <Row className='f-pay-links'>
                                            <Conditional condition={hasRemainingBalance() && !invoice?.IsLockedForPayment}>
                                                <Conditional condition={!!invoice?.CanPayWithACH}>
                                                    <Link to='/ach'>
                                                        <Button variant='success' onClick={() => { }}><FontAwesomeIcon icon='circle-check' />Pay With ACH</Button>
                                                    </Link>
                                                </Conditional>
                                                <Conditional condition={!!invoice?.CanPayWithCC}>
                                                    <Link to='/credit'>
                                                        <Button variant='success' onClick={() => { }}><FontAwesomeIcon icon='circle-check' /> Pay With Credit</Button>
                                                    </Link>
                                                </Conditional>
                                            </Conditional>
                                            <Conditional condition={!!invoice?.IsLockedForPayment}>
                                                <span className='error'>A payment has been submitted for this invoice but has not yet cleared, preventing the remaining balance from being confirmed. Please contact the company you are trying to pay or reach out to <a href="mailto:help@factorapp.com">help@factorapp.com</a> to check the status if you are trying to make an additional payment on this invoice.</span>
                                            </Conditional>
                                        </Row>
                                    </Col>
                                    <Col xs={12} className="f-doc-links d-lg-none">
                                        {getInvoiceLink('invoice', 'Invoice')}
                                        <Conditional condition={!!invoice?.HasHourlyDetail}>
                                            | {getInvoiceLink('hourly', 'Hourly Detail')}
                                        </Conditional>
                                        <Conditional condition={!!invoice?.HasExpenseAttachments}>
                                            | {getInvoiceLink('attachment', 'Expense Attachments')}
                                        </Conditional>
                                    </Col>
                                </Row>
                                <Row>
                                    <LoadableComponent loading={!invoicePdf}>
                                        {invoicePdf && <PdfViewer
                                            base64Pdf={invoicePdf}
                                        />}
                                    </LoadableComponent>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" lg="4">
                        <PaymentBreakdown screen="invoice" />
                        <HelpSection />
                    </Col>
                </Row>
            </Conditional>
            <Conditional condition={!!invoice && !invoice.CanBeViewed}>
                <Alert variant='danger'>This invoice is not available for viewing.</Alert>
            </Conditional>
        </ScrollablePage>
    );
};

export default ViewInvoice;