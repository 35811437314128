import Payment from '@/models/payment';
import PaymentLock from '@/models/payment-lock';
import Api from '@/services/api';

class PaymentApi {
    submitPayment(payment: Payment, onSuccess: () => void, onKnownFailure: (error: any) => void) {
        return Api.post({ url: `/api/v1/payment`, body: payment, onSuccess, onKnownFailure });
    }

    lockPayment(lock: PaymentLock, onSuccess: () => void, onKnownFailure: (error: any) => void) {
        return Api.post({ url: `/api/v1/payment/lock`, body: lock, onSuccess, onKnownFailure });
    }
}

export default new PaymentApi();